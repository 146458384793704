import cn from 'classnames';
import React from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ArrowIcon } from '@/components/_icons/arrow-icon';
import { TSlider } from '@/types/sliders';

import { theme } from '@/shared/theme';

import { SliderItem } from './slider-item';
import * as Styled from './styled';
import 'swiper/css';

type Props = {
    sliders: TSlider[];
};

export const Sliders: React.FC<Props> = ({ sliders }) => {
    return (
        <Styled.Wrapper>
            <Swiper
                loop={true}
                modules={[Pagination, Navigation, Autoplay]}
                slidesPerView={1}
                pagination={{
                    el: '.bullets',
                    bulletClass: 'my-bullet',
                    bulletActiveClass: 'bullet-active',
                    clickable: true,
                }}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                draggable
                navigation={{
                    prevEl: `.prev`,
                    nextEl: `.next`,
                }}
            >
                {sliders.map((sliderItem: TSlider) => (
                    <SwiperSlide key={sliderItem.name}>
                        <SliderItem {...sliderItem} />
                    </SwiperSlide>
                ))}
                <Styled.NavigationButton className="prev">
                    <ArrowIcon color={theme.color.grey} />
                </Styled.NavigationButton>
                <Styled.NavigationButton className="next">
                    <ArrowIcon color={theme.color.grey} />
                </Styled.NavigationButton>
                <Styled.Bullets>
                    <div className="bullets">
                        {sliders.map((_, i) => (
                            <div key={i} className={cn('my-bullet', { 'bullet-active': i === 0 })} />
                        ))}
                    </div>
                </Styled.Bullets>
            </Swiper>
        </Styled.Wrapper>
    );
};
