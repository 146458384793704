import React from 'react';

type TProps = {
    width?: number;
    startGradient: string;
    stopGradient: string;
    id: string;
};

export const ArrowNavigateIcon: React.FC<TProps> = ({ width = 16, id, startGradient, stopGradient }) => {
    return (
        <svg width={width} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z"
                fill={`url(#${id})`}
            />
            <defs>
                <linearGradient
                    id={id}
                    x1="0.4835"
                    y1="0.40747"
                    x2="18.5137"
                    y2="5.80999"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={startGradient} />
                    <stop offset="1" stopColor={stopGradient} />
                </linearGradient>
            </defs>
        </svg>
    );
};
