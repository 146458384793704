import Link from 'next/link';
import React, { useMemo } from 'react';

import { TSlider } from '@/types/sliders';

import { StyledSliderItem } from './styled';

export const SliderItem: React.FC<TSlider> = ({ anchorTarget, images, link }) => {
    const smallImg = useMemo(() => {
        const imgSlider = images.find((el) => el.maxWidth === 'md');

        return `${imgSlider?.url}`;
    }, []);

    const bigImg = useMemo(() => {
        const imgSlider = images.find((el) => el.maxWidth === 'default');

        return `${imgSlider?.url}`;
    }, []);

    const slider = (
        <>
            <StyledSliderItem background={bigImg} />
            <StyledSliderItem background={smallImg} className="hidden-by-md" />
        </>
    );

    if (!link) return slider;

    return (
        <Link href={link} target={anchorTarget}>
            {slider}
        </Link>
    );
};
