import { styled } from 'styled-components';

import { rowYMiddle } from '@/components/_shared/mixins.styled';

export const StyledLinkBlock = styled.span<{ $hoverColor: 'primary' | 'default' }>`
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease-in;
    ${rowYMiddle};

    svg {
        transition: fill 0.3s ease-in;
    }

    ${({ $hoverColor, theme }) =>
        $hoverColor === 'primary' &&
        `
    &:hover {
        color: ${theme.color.primary};
        transition: color 0.3s ease-out;
        svg {
            fill: ${theme.color.primary};
            transition: fill 0.3s ease-out;
        }
    }
    `}
`;
