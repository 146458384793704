import { styled } from 'styled-components';

export const Wrapper = styled.div`
    a {
        font-size: 14px;
        font-weight: 500;

        > span {
            display: flex;
            gap: 10px;
            align-items: center;
        }
    }
`;
