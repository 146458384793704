import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { ARTICLES_URL } from '@/constants/paths';
import { IMAGE_ALT_CUT_LENGTH } from '@/constants/settings';
import { Article } from '@/types/articles-page';
import { cutString } from '@/utils/string';

import { useTranslate } from '@/shared/hooks/use-translate';

import { StyledArticleCard, StyledArticleCardContent, StyledArticleCardTitle, StyledArticleCardButton } from './styled';

export const ArticleCard: React.FC<Article> = ({ imageUrl, title, slug }) => {
    const { t } = useTranslate();

    return (
        <StyledArticleCard>
            {imageUrl && (
                <div className="image-block">
                    <Image src={imageUrl} alt={cutString(title, IMAGE_ALT_CUT_LENGTH)} fill />
                </div>
            )}
            <StyledArticleCardContent>
                {/* TODO в дальнейшем категории могут пригодиться */}
                {/*<StyledArticleCardCategory>{categoryName}</StyledArticleCardCategory>*/}
                <StyledArticleCardTitle>{title}</StyledArticleCardTitle>
            </StyledArticleCardContent>
            <Link href={`${ARTICLES_URL}/${slug}`}>
                <StyledArticleCardButton>{t('Читать')} 👀</StyledArticleCardButton>
            </Link>
        </StyledArticleCard>
    );
};
