import { Grid } from 'antd';
import React, { useEffect, useState } from 'react';

import { ArticleCard } from '@/components/elements/article-card';
import { Article } from '@/types/articles-page';

import { ArticlesSlider } from '../articles-slider';

import { StyledPopularProducts } from './styled';

const { useBreakpoint } = Grid;

type Props = {
    articles: Article[];
};

export const PopularArticles: React.FC<Props> = ({ articles }) => {
    const breakPoint = useBreakpoint();

    const [isSliderDisabled, setIsSliderDisabled] = useState(false);

    useEffect(() => {
        setIsSliderDisabled(!!breakPoint.xs);
    }, [breakPoint]);

    return (
        <StyledPopularProducts>
            {!isSliderDisabled && <ArticlesSlider articles={articles} />}
            {isSliderDisabled && (
                <>
                    {articles.map((product, index) => (
                        <ArticleCard {...product} key={index} />
                    ))}
                </>
            )}
        </StyledPopularProducts>
    );
};
