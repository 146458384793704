import React from 'react';

import { ErrorPage } from '@/components/elements/error-page';
import { NOT_FOUND_PAGE, SERVER_ERROR, STATUS_CODE_OK } from '@/constants/settings';

import { Layout } from '..';

type Props = {
    statusCode?: number;
    children: React.ReactNode;
};

export const CatchingErrorLayout: React.FC<Props> = ({ children, statusCode = STATUS_CODE_OK }) => {
    if (statusCode === NOT_FOUND_PAGE) {
        return (
            <Layout>
                <ErrorPage errorType={NOT_FOUND_PAGE} />
            </Layout>
        );
    }

    if (statusCode === SERVER_ERROR) {
        return (
            <Layout>
                <ErrorPage errorType={SERVER_ERROR} />
            </Layout>
        );
    }

    return <>{children}</>;
};
