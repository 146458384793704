import React from 'react';

import { FaqItem, FaqItemProps } from '../faq-item';

import { StyledFaq } from './styled';

type Props = {
    faqList: FaqItemProps[];
};

export const Faq: React.FC<Props> = ({ faqList }) => {
    const faqItems = faqList.map((faq, index) => <FaqItem {...faq} key={index} />);

    return (
        <StyledFaq>
            <ul>{faqItems}</ul>
        </StyledFaq>
    );
};
