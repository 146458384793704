import { TReadParamsProperties, ResourceService } from '@/api/baseServices/resource-service';
import { TMetaDataReturned } from '@/types/settings';

import { TMeta } from '../baseServices/types';

export const MetaDataService = ResourceService('metadata');

type TMetaData = {
    header: string;
    description: string;
};

type ResponseType = {
    meta: TMeta;
    data: TMetaData[];
};

export const getMetaDataPage = async (pageUrl: string, locale: string): Promise<TMetaDataReturned> => {
    const responseMetaData = await MetaDataService.read<ResponseType, TReadParamsProperties>({
        params: {
            // eslint-disable-next-line camelcase
            page_url: pageUrl,
            locale,
        },
    });

    const title = responseMetaData.data[0]?.header;

    const description = responseMetaData.data[0]?.description;

    return { title, description };
};
