import { styled } from 'styled-components';

import { StyledArticleCard } from '@/components/elements/article-card/styled';

export const StyledPopularProducts = styled.div`
    & > ${StyledArticleCard} {
        margin-bottom: 12px;
    }

    & > ${StyledArticleCard}:last-of-type {
        margin-bottom: 0;
    }
`;
