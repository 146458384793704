import React, { FC } from 'react';
import { useTheme } from 'styled-components';

import { ArrowIcon } from '@/components/_icons/arrow-icon';
import { LinkBlock } from '@/components/elements/UI/link-block';

import * as Styled from './styled';

type TProps = {
    title: string;
    href: string;
};

export const LinkPageSection: FC<TProps> = ({ href, title }) => {
    const theme = useTheme();
    return (
        <Styled.Wrapper>
            <LinkBlock href={href}>
                <span>{title}</span>
                <span>
                    <ArrowIcon color={theme.color.dark} width={6} />
                </span>
            </LinkBlock>
        </Styled.Wrapper>
    );
};
