import { NextPageContext } from 'next';

import { TokenService } from '@/api/baseServices/tokenService/token-service';
import { loadSocialLinks } from '@/components/modules/social-links';
import { TRootStateType } from '@/store';
import { loadCities } from '@/store/reducers/cities/action-creators';
import { loadContentBlocks } from '@/store/reducers/content-blocks/action-creators';
import { loadMenus } from '@/store/reducers/menus/action-creators';
import { loadAllCategories } from '@/store/reducers/rent/action-creators';
import { setIsDefaultSettingsLoaded, setTechnicalError } from '@/store/reducers/settings/slice';
import { loadCityUser, resetUserData } from '@/store/reducers/user/slice';
import { TUserModelState } from '@/store/reducers/user/types';
import { isBrowser } from '@/utils/is-browser';

import { loadUserData } from '../reducers/user/action-creators';
import { TAppDispatch } from '../types';

export type TGetDefaultSettingsSiteParams = {
    getState: () => TRootStateType;
    dispatch: TAppDispatch;
    locale: string;
    context: NextPageContext;
};

const loadSettingsSite = async (params: TGetDefaultSettingsSiteParams) => {
    const { dispatch, locale } = params;
    dispatch(setTechnicalError(null));
    dispatch(resetUserData());

    await Promise.all([
        dispatch(loadMenus({ locale })),
        dispatch(loadContentBlocks({ locale })),
        dispatch(loadAllCategories()),
        dispatch(loadCities()),
        dispatch(loadSocialLinks()),
    ]);

    dispatch(setIsDefaultSettingsLoaded(true));
};

export const loadDefaultData = async (params: TGetDefaultSettingsSiteParams): Promise<{ user: TUserModelState }> => {
    const { getState, dispatch, context } = params;

    const state: TRootStateType = getState();

    const isDefaultSettingsLoaded = state.settings.isDefaultSettingsLoaded;

    TokenService.removeCountRefreshToken(context);

    if (!isBrowser()) {
        await loadSettingsSite(params);
    }

    if (!isDefaultSettingsLoaded) {
        await loadSettingsSite(params);
    }

    await dispatch(loadCityUser(context));

    const token = TokenService.getAccessToken(context);

    if (token && !getState().user.data) {
        await dispatch(loadUserData(context));
    }

    return {
        user: getState().user,
    };
};
