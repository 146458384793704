import { styled } from 'styled-components';

import { mq } from '@/components/_shared/media.styled';
import { rowXBetweenYCenter } from '@/components/_shared/mixins.styled';

export const StyledCategories = styled.div`
    margin-top: 24px;
`;

export const StyledCategoriesList = styled.ul`
    display: flex;
    flex-wrap: wrap;

    &::markr ${mq.gt.sm} {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;
    }
`;

export const StyledCategoriesItem = styled.li<{ background: string; color?: string; $anotherStyle?: boolean }>`
    background: ${({ $anotherStyle, background, theme }) => ($anotherStyle ? theme.color.secondary : background)};
    border-radius: 12px;
    padding: 24px;
    position: relative;
    flex: 1 1 100%;
    margin-bottom: 16px;
    cursor: pointer;

    & {
        list-style-type: none;
    }

    & a i {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 70px;
        background-color: ${({ $anotherStyle, theme }) =>
            $anotherStyle ? theme.color.primary : theme.color.secondary};
        justify-content: center;
        width: 38px;
        height: 38px;
    }

    & a > h3 {
        position: relative;
        z-index: 2;
        font-weight: 700;
        margin-bottom: 40px;
        color: ${({ color, $anotherStyle, theme }) => ($anotherStyle ? theme.color.dark : color ? color : '#fff')};
    }

    & a span:last-child {
        position: absolute;
        height: 130px;
        width: 150px;
        right: 16px;
        top: 12px;

        img {
            min-width: initial !important;
            width: initial !important;
            left: initial !important;
        }

        ${mq.lt.lg} {
            top: 6px;
        }

        ${mq.lt.sm} {
            height: 110px;
            top: 16px;
        }
    }

    &.fl40 {
        h3 {
            height: 24px;
        }
    }

    &.fl20 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: initial;

        a {
            ${rowXBetweenYCenter};
            width: 100%;
        }

        a h3 {
            margin-bottom: 0;
        }
    }

    ${mq.gt.md} {
        align-items: center;

        &.fl50 {
            a h3 {
                font-size: ${({ theme }) => theme.fontSize.xxl};
            }

            a img {
                top: 5%;
            }
        }

        &.fl40 {
            font-size: ${({ theme }) => theme.fontSize.xl};

            a img {
                top: 13%;
            }
        }

        &.fl20 {
            display: block;
            font-size: ${({ theme }) => theme.fontSize.lg};
            height: calc(100% - 16px);

            a {
                display: inline;
            }

            a h3 {
                margin-bottom: 40px;
            }
        }

        & a > span:last-child {
            max-height: 130px;
        }

        & a > p {
            font-weight: 700;
        }

        & + & {
            margin-top: 0;
        }
    }
    ${mq.lg} {
        &.fl20 {
            height: calc(100% - 32px);
        }
    }
`;
