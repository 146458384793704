import { styled } from 'styled-components';

import { StyledFaqItem } from '@/components/modules/faq/ui/faq-item/styled';

export const StyledFaq = styled.div`
    background-color: ${({ theme }) => theme.color.secondary};
    border-radius: 12px;
    padding: 32px;

    & ${StyledFaqItem} {
        margin-bottom: 16px;
    }

    & ${StyledFaqItem}:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }
`;
