import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';

import { MenuBookIcon } from '@/components/_icons/menu-book-icon';
import { StyledContainer } from '@/components/_shared/container';
import { PopularProducts } from '@/components/modules/popular-products';
import { useAppSelector } from '@/store/hooks';

import { useTranslate } from '@/shared/hooks/use-translate';

import { errorsPageNumbers } from '../../../constants/settings';
import { PageSection } from '../page-section/ui/page-section';
import { Button } from '../UI/button';

import { StyledErrorPage, StyledErrorPageText, StyledErrorPageTitle } from './styled';

type Props = {
    errorType: 404 | 500;
};

export const ErrorPage: React.FC<Props> = ({ errorType = 404 }) => {
    const { t } = useTranslate();

    const router = useRouter();

    const popularProducts = useAppSelector((state) => state.rent.popularProducts.data);

    const cityUser = useAppSelector((state) => state.user.cityUser);

    return (
        <StyledContainer>
            <StyledErrorPage>
                <div className="smile-block">
                    <Image src="/static/icons/thoughtful_smile.png" alt="thoughtful smile" width={86} height={90} />
                    <Image src="/static/icons/thoughtful_smile.png" alt="thoughtful smile" width={59} height={59} />
                    <Image src="/static/icons/monocol_smile.png" alt="monocol smile" width={46} height={49} />
                    <Image src="/static/icons/thoughtful_smile.png" alt="thoughtful smile" width={59} height={59} />
                    <Image src="/static/icons/monocol_smile.png" alt="monocol_smile" width={49} height={49} />
                </div>
                <StyledErrorPageTitle> {errorType} </StyledErrorPageTitle>
                <StyledErrorPageText> {errorsPageNumbers[errorType]()} </StyledErrorPageText>
                <Button
                    variant="dark"
                    onClick={() => router.replace(`/${cityUser?.slug}`)}
                    fontSize="md"
                    weight="700"
                    aria-label="К каталогу"
                    icon={<MenuBookIcon />}
                >
                    {t('К каталогу')}
                </Button>
            </StyledErrorPage>
            <PageSection title="Самые популярные 🔥">
                <PopularProducts products={popularProducts} />
            </PageSection>
        </StyledContainer>
    );
};
