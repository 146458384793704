import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';
import type { NextPageContext } from 'next';

import { ArticlesService } from '@/api/arenta/articles-service';
import { TReadParamsProperties } from '@/api/baseServices/resource-service';
import { TMeta } from '@/api/baseServices/types';
import { NOT_FOUND_PAGE, SERVER_ERROR, SIZE_SHOWN_ARTICLES, STATUS_CODE_OK } from '@/constants/settings';
import { Article, ArticleOnePageProps, ArticlesModuleProps } from '@/types/articles-page';
import { TStatusCodePage } from '@/types/utils';
import { getNumberFromString } from '@/utils/querify';

type ResponseType = {
    meta: TMeta;
    data: Article[];
};

export const getDynamicArticlePageProps = async (
    context: NextPageContext,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    // Store: any,
): Promise<ArticlesModuleProps | ArticleOnePageProps | TStatusCodePage> => {
    const { query, locale } = context;

    const slug: string = (query.slug as string) || '';

    // Проверка на наличие в слаге ключевого слова page-

    if (slug !== undefined && slug.match(/page-/gi)) {
        // Запрашиваем статьи по пагинации

        const page = getNumberFromString(slug.replace(/page-/gi, ''));

        return await getArticlesWithMainData(page, locale);
    }
    // Запрашиваем одну статью

    return await getOneArticle(slug, locale);
};

export const getArticles = async (
    page: number,
    locale?: string,
    isMain?: boolean,
    isPopular?: boolean,
): Promise<ResponseType> => {
    const popular = isPopular !== undefined ? { isPopular } : {};

    const main = isMain !== undefined ? { isMain } : {};

    return await ArticlesService.read<ResponseType, TReadParamsProperties>({
        params: {
            page: page,
            itemsPerPage: SIZE_SHOWN_ARTICLES,
            ...main,
            ...popular,
            locale,
        },
    });
};

export const getArticlesWithMainData = async (
    page: number,
    locale?: string,
): Promise<ArticlesModuleProps | TStatusCodePage> => {
    let statusCode = STATUS_CODE_OK;

    try {
        const responseArticles = await getArticles(page, locale, false);

        const responseMainArticle = await ArticlesService.read<ResponseType, TReadParamsProperties>({
            params: {
                isMain: true,
                locale,
            },
        });

        const totalPage = responseArticles.meta.totalItems;

        const currentPage = responseArticles.meta.currentPage;

        return {
            articles: responseArticles.data,
            totalPage,
            currentPage,
            mainArticle: responseMainArticle.data[0],
            typePage: 'articles_page',
            statusCode: STATUS_CODE_OK,
        };
    } catch (err) {
        /* eslint-disable no-console */
        console.error('getArticlesWithMainData error', err);

        const error = err as AxiosError;

        statusCode = error.response?.status === NOT_FOUND_PAGE ? NOT_FOUND_PAGE : SERVER_ERROR;

        captureException(error);
    }

    return { statusCode };
};

export const getOneArticle = async (slug: string, locale?: string): Promise<ArticleOnePageProps | TStatusCodePage> => {
    let statusCode = STATUS_CODE_OK;

    try {
        const responseArticle = await ArticlesService.readOne<Article, TReadParamsProperties>({
            identifier: `${slug}`,
            params: {
                locale,
            },
        });

        return {
            article: responseArticle,
            statusCode: STATUS_CODE_OK,
            typePage: 'article_one_page',
        };
    } catch (err) {
        /* eslint-disable no-console */
        console.error('getOneArticle error', err);

        const error = err as AxiosError;

        statusCode = error.response?.status === NOT_FOUND_PAGE ? NOT_FOUND_PAGE : SERVER_ERROR;

        captureException(error);
    }

    return { statusCode };
};
