import { TFaq } from '@/types/faq';

import { ResourceService, TReadParamsProperties } from '../baseServices/resource-service';
import { TMeta } from '../baseServices/types';

export const FaqService = ResourceService('faqs');

type ResponseType = {
    meta: TMeta;
    data: TFaq[];
};

export const getFaqs = async (locale: string, isPopular?: boolean): Promise<ResponseType> => {
    const popular = isPopular !== undefined ? { isPopular } : {};

    const responseQuestions = await FaqService.read<ResponseType, TReadParamsProperties>({
        params: {
            locale,
            ...popular,
        },
    });

    return responseQuestions;
};
