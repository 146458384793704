import { styled } from 'styled-components';

import { mq } from '@/components/_shared/media.styled';

export const Wrapper = styled.section`
    user-select: none;
    width: 100%;
    position: relative;

    & .swiper {
        width: 100%;
    }
`;

export const NavigationButton = styled.div`
    cursor: pointer;
    z-index: 3;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 42px;
    height: 42px;
    background: rgb(255 255 255 / 70%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &:hover {
        background: rgb(255 255 255 / 100%);
    }

    &.prev {
        left: 24px;

        svg {
            transform: rotate(180deg);
        }
    }

    &.next {
        right: 24px;
    }
    ${mq.lt.md} {
        display: none;
    }
`;

export const Bullets = styled.div`
    .bullets {
        position: relative;
        top: -24px;
        width: 100%;
        height: 20px;
        display: flex;
        z-index: 2;
        cursor: pointer;
        justify-content: center;
        align-items: center;

        .my-bullet {
            position: relative;
            width: 20px;
            height: 20px;

            &::before {
                content: '';
                top: 50%;
                border-radius: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                display: block;
                width: 8px;
                position: relative;
                height: 8px;
                background: ${({ theme }) => theme.color.neutral100};
                opacity: 0.5;
            }

            &:not(.bullet-active):hover {
                &::before {
                    width: 10px;
                    height: 10px;
                }
            }
        }

        .bullet-active {
            &::before {
                width: 12px;
                height: 12px;
                background: ${({ theme }) => theme.color.neutral100};
                opacity: 1;
            }
        }
    }
`;
