import { Col, Row } from 'antd';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { ArrowNavigateIcon } from '@/components/_icons/arrow-navigate-icon';
import { IMAGE_ALT_CUT_LENGTH } from '@/constants/settings';
import { useAppSelector } from '@/store/hooks';
import { cutString } from '@/utils/string';

import { useTranslate } from '@/shared/hooks/use-translate';

import { StyledCategories, StyledCategoriesItem } from './styled';

type Props = {
    anotherStyle?: boolean;
};

export const Categories: React.FC<Props> = ({ anotherStyle = false }) => {
    const { t } = useTranslate();

    const rentState = useAppSelector((state) => state.rent);

    const mainCategories = rentState.mainCategories.data;

    const allCategories = rentState.allCategories.data;

    const cityUser = useAppSelector((state) => state.user.cityUser);

    if (mainCategories?.length === 0) return <></>;

    return (
        <StyledCategories>
            <Row gutter={16}>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                    <StyledCategoriesItem
                        background="linear-gradient(106.28deg, #CE42B8 2.4%, #6B9DFD 100%)"
                        className="fl50"
                        $anotherStyle={anotherStyle}
                    >
                        <Link href={`${cityUser?.slug}/${mainCategories[0]?.slug}`}>
                            <h3> {mainCategories[0]?.name} </h3>
                            <i>
                                <ArrowNavigateIcon
                                    startGradient={anotherStyle ? '#fff' : '#CE42B8'}
                                    stopGradient={anotherStyle ? '#fff' : '#6B9DFD'}
                                    id="gradiendt1"
                                />
                            </i>
                            <span>
                                {mainCategories[0]?.image && (
                                    <Image
                                        fill
                                        src={mainCategories[0]?.image}
                                        alt={cutString(mainCategories[0]?.name, IMAGE_ALT_CUT_LENGTH)}
                                    />
                                )}
                            </span>
                        </Link>
                    </StyledCategoriesItem>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                    <StyledCategoriesItem
                        background="linear-gradient(106.28deg, #CE4285 2.4%, #FD6B6B 100%)"
                        className="fl50"
                        $anotherStyle={anotherStyle}
                    >
                        <Link href={`${cityUser?.slug}/${mainCategories[1]?.slug}`}>
                            <h3> {mainCategories[1]?.name} </h3>
                            <i>
                                <ArrowNavigateIcon
                                    startGradient={anotherStyle ? '#fff' : '#CE4285'}
                                    stopGradient={anotherStyle ? '#fff' : '#FD6B6B'}
                                    id="gradiendt2"
                                />
                            </i>
                            <span>
                                {mainCategories[1]?.image && (
                                    <Image
                                        fill
                                        src={mainCategories[1].image}
                                        alt={cutString(mainCategories[1]?.name, IMAGE_ALT_CUT_LENGTH)}
                                    />
                                )}
                            </span>
                        </Link>
                    </StyledCategoriesItem>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24}>
                    <StyledCategoriesItem
                        background="linear-gradient(106.28deg, #5E42CE 2.4%, #6B9DFD 100%)"
                        className="fl40"
                        $anotherStyle={anotherStyle}
                    >
                        <Link href={`${cityUser?.slug}/${mainCategories[2]?.slug}`}>
                            <h3> {mainCategories[2]?.name} </h3>
                            <i>
                                <ArrowNavigateIcon
                                    startGradient={anotherStyle ? '#fff' : '#5E42CE'}
                                    stopGradient={anotherStyle ? '#fff' : '#6B9DFD'}
                                    id={anotherStyle ? '#fff' : 'gradiendt3'}
                                />
                            </i>
                            <span>
                                {mainCategories[2]?.image && (
                                    <Image
                                        fill
                                        src={mainCategories[2].image}
                                        alt={cutString(mainCategories[2]?.name, IMAGE_ALT_CUT_LENGTH)}
                                    />
                                )}
                            </span>
                        </Link>
                    </StyledCategoriesItem>
                </Col>
                <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24}>
                    <StyledCategoriesItem
                        background="linear-gradient(106.28deg, #24C60A 2.4%, #33C8F8 100%);"
                        className="fl40"
                        $anotherStyle={anotherStyle}
                    >
                        <Link href={`${cityUser?.slug}/${mainCategories[3]?.slug}`}>
                            <h3> {mainCategories[3]?.name} </h3>
                            <i>
                                <ArrowNavigateIcon
                                    startGradient={anotherStyle ? '#fff' : '#24C60A'}
                                    stopGradient={anotherStyle ? '#fff' : '#33C8F8'}
                                    id="gradiendt4"
                                />
                            </i>
                            <span>
                                {mainCategories[3].image && (
                                    <Image
                                        fill
                                        src={mainCategories[3].image}
                                        alt={cutString(mainCategories[3]?.name, IMAGE_ALT_CUT_LENGTH)}
                                    />
                                )}
                            </span>
                        </Link>
                    </StyledCategoriesItem>
                </Col>
                {!anotherStyle && (
                    <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                        <StyledCategoriesItem background="#ECECF0" color="#1F2547" className="fl40">
                            <Link href={cityUser?.slug}>
                                <h3>
                                    {t('Еще {count} категорий', {
                                        count: allCategories?.length - mainCategories?.length,
                                    })}
                                </h3>
                                <i>
                                    <ArrowNavigateIcon startGradient="#818C99" stopGradient="#818C99" id="gradiendt5" />
                                </i>
                            </Link>
                        </StyledCategoriesItem>
                    </Col>
                )}
            </Row>
        </StyledCategories>
    );
};
