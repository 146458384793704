import cn from 'classnames';
import React, { useState } from 'react';

import {
    StyledFaqItem,
    StyledFaqItemQuestion,
    StyledFaqItemToggleButton,
    StyledFaqItemAnswer,
    FAQ_ITEM_OPENED_CLASS,
} from './styled';

export type FaqItemProps = {
    question: string;
    answer: string;
};

export const FaqItem: React.FC<FaqItemProps> = ({ question, answer }) => {
    const [isOpened, setIsOpened] = useState(false);

    const handleToggleButtonClick = () => setIsOpened(!isOpened);

    return (
        <StyledFaqItem className={cn({ [FAQ_ITEM_OPENED_CLASS]: isOpened })} $isOpened>
            <StyledFaqItemQuestion onClick={handleToggleButtonClick}>
                <h3>{question}</h3>
                <StyledFaqItemToggleButton />
            </StyledFaqItemQuestion>
            <StyledFaqItemAnswer>
                <div dangerouslySetInnerHTML={{ __html: answer as string }} />
            </StyledFaqItemAnswer>
        </StyledFaqItem>
    );
};
