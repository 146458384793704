import Link from 'next/link';
import React from 'react';

import { StyledLinkBlock } from './styled';

type TProps = {
    href: string;
    children: React.ReactNode;
    hoverColor?: 'primary' | 'default';
};

export const LinkBlock: React.FC<TProps> = ({ href = '/', children, hoverColor = 'primary' }) => {
    return (
        <Link href={href}>
            <StyledLinkBlock $hoverColor={hoverColor}>{children}</StyledLinkBlock>
        </Link>
    );
};
