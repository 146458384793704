import { styled } from 'styled-components';

import { mq } from '@/components/_shared/media.styled';
import { colBothMiddle } from '@/components/_shared/mixins.styled';

export const StyledErrorPage = styled.div`
    ${colBothMiddle};
    margin-top: 82px;
    margin-bottom: 106px;
    position: relative;

    button {
        padding: 12px 57px;
    }

    .smile-block {
        position: absolute;
        width: 100%;
        height: 100%;

        > img {
            position: absolute !important;

            img {
                opacity: 0.6;
            }

            &:first-child {
                width: 86px;
                height: 90px;
                right: 8%;
                top: -5%;
                transform: rotate(-25deg);
            }

            &:nth-child(2) {
                width: 59px;
                height: 59px;
                left: 14%;
                top: 7%;
                transform: rotate(15deg);
            }

            &:nth-child(3) {
                width: 46px;
                height: 49px;
                left: 12%;
                bottom: 12%;
                transform: rotate(35deg);
            }

            &:nth-child(4) {
                width: 59px;
                height: 59px;
                right: 14%;
                bottom: 9%;
                transform: rotate(-10deg);
            }
        }
    }
    ${mq.lt.md} {
        margin-top: 47px;

        button {
            padding: 8px 16px;
        }

        .smile-block {
            position: absolute;
            width: 100%;
            height: 100%;

            > img {
                position: absolute !important;

                &:first-child {
                    top: -19%;
                    width: 52px;
                    height: 55px;
                }

                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    display: none !important;
                }
            }
        }
    }
`;

export const StyledErrorPageTitle = styled.h1`
    font-size: 90px;
    color: ${({ theme }) => theme.color.primary};
    font-weight: 700;
    margin-bottom: 24px;
`;

export const StyledErrorPageText = styled.div`
    p {
        font-size: ${({ theme }) => theme.fontSize.lg};
        font-weight: 500;
        line-height: 130%;
        text-align: center;
    }

    margin-bottom: 40px;

    ${mq.lt.md} {
        p {
            font-size: ${({ theme }) => theme.fontSize.sm};
            font-weight: 400;
        }

        margin-bottom: 32px;
    }
`;
