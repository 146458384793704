import { TSlider } from '@/types/sliders';

import { ResourceService, TReadParamsProperties } from '../baseServices/resource-service';
import { TMeta } from '../baseServices/types';

export const SlidersService = ResourceService('sliders');

type ResponseType = {
    meta: TMeta;
    data: TSlider[];
};

export const getSliders = async (locale?: string): Promise<ResponseType> => {
    const responseSliders = await SlidersService.read<ResponseType, TReadParamsProperties>({
        params: {
            locale,
        },
    });

    return responseSliders;
};
